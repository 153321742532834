<template>
  <div class="fadeInRight shoppingCart">
          <div class="title-box clearfix">
          
            <el-form inline ref="shoppingCart" v-model="shoppingCart" class="hook right themed-form search-form">
               <el-form-item label="客户">
					<el-select v-model="shoppingCart.userId" placeholder="客户" clearable filterable  >
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item) in customerList" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
					</el-select>
		     </el-form-item>
                <el-form-item>
					<el-button icon="el-icon-search" type="primary" class="themed-button" @click="onSearch">搜索查询</el-button>
				</el-form-item>
            
            </el-form>
        </div>
    <div >
 <div class="title-box clearfix">
      <span class="m-title">我的购物车</span>
    </div>
    <div class="content-box">
      <div v-if="shoppingList.length>0">
        <div class="shoppingListHead">
          <span class="flex-6">商品详情</span>
          <span class="flex-1">客户</span>
          <span class="flex-2">套餐价格</span>
          <span class="flex-2">总周期</span>
          <span class="flex-2">沉默期</span>
          <span class="flex-2">单张卡费</span>
          <span class="flex-3">卡片数量</span>
          <span class="flex-1">小计</span>
          <span class="flex-2">操作</span>
        </div>
        <div class="shoppingListBody">
          <div
            class="list_body_item"
            v-for="(item, index) in shoppingList"
            :key="index"
            :class="{isexpire:item.expire}"
          >
            <div class="flex-6">
              <div class="item-1-1">
                <img width="80px" height="80px" :src="item.imageUrl" />
              </div>
              <div class="item-1-2 width-70">
                <div class="goods-attribute">
                  <p class="margin-bottom10">
                    <span>{{ item.productName }}</span
                    >&nbsp;<span>{{  _getCategory(item.accountType, 1) }}</span
                    >&nbsp;<span>{{item.mealTypeStr}}套餐</span>
                  </p>
                  <p class="color-666 f-size-l9 left-width">
                    <span>材料：</span><span>{{ item.materialStr }}</span
                    ><br />
                    <span>规格：</span><span>{{ item.specStr }}</span>
                  </p>
                  <div class="color-666 f-size-l9 attr-rules left-width">
                    <div>
                      <span>网络模式：</span><span>{{ item.netModelStr }}</span>
                    </div>
                  </div>
                  <div
                    class="color-666 f-size-l9 attr-rules"
                    style="float: right; margin-top: -60px; margin-bottom: 10px"
                  >
                    <div class="right-width">
                      <span>卡片功能：</span><span>{{ item.cardFuncStr=="null"?"无":item.cardFuncStr }}</span>
                    </div>
                    <!-- <div class="right-width">
                      <span>网络增值服务：</span><span>{{item.netServiceStr }}</span>
                    </div> -->
                    <div class="right-width">
                      <span>套餐分类：</span><span>{{ item.mealName }}</span>
                    </div>
                    <div class="right-width" style="display:none">
                      <span>已打开自动续费功能：</span>{{ item.autoRenew == 1?"是":"否" }}
                    </div>
                    <el-tooltip class="item" effect="dark" placement="top">
                      <div slot="content" v-html="totalInfo(item)"></div>
                      <el-button type="text">显示全部</el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
            </div>
               <div class="flex-1 item">
            <span>{{item.userName}}</span>
            </div>
            <div class="flex-2 item">
                 <span v-if="item.expire">-</span>
             <span v-else>{{"¥ " + item.mealPrice }}</span>
              <!-- <div>{{"¥ " + item.mealPrice }}</div> -->
            </div>
            <div class="flex-2 item">
              <span>{{ item.subscribeCount }}月</span>
            </div>
            <div class="flex-2 item">{{ item.silentCount }}月</div>
            <div class="flex-2 item">
              <span v-if="item.expire">-</span>
             <span v-else>  {{ "¥ " + item.singleFee }}</span>
             </div>
            <div
              class="flex-3 item"
              style="flex-direction: column; margin-top: 18px"
            >
              <el-input-number
                v-model="item.cardCount"
                @change="handleChange(item.id,item.cardCount)"
                :min="1"
                :max="10000000"
              ></el-input-number>
              <p style="margin-top: 2px; font-size: 12px">起订量1张，步长1张</p>
            </div>
            <div class="flex-1 item">
              <span v-if="item.expire">-</span>
             <span v-else> {{ "¥" + parseFloat(item.totalFee).toFixed(2) }}</span>
            </div>
            <div class="flex-2 item">
               <el-button type="primary" v-if="item.expire">失效</el-button>
              <i
                class="el-dialog__close el-icon el-icon-close del"
                @click="delShoppingItem(index,item.id)"
              ></i>

            </div>
          </div>
        </div>
        <div class="shoppingListFooter" style="display:none">
          <span class="f-size-l9 color-999" style="display:none;">全场满500元包邮</span>
          <div>
            <span class="color-666 f-bold">总计（不含运费）</span>
            <span class="f-size-l3 f-bold">￥{{ getTotalPrice }}</span>
          </div>
        </div>
        <el-pagination :current-page.sync="page"  :page-sizes="[5,10,15,50,100,1000]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange" @current-change="onPageChange"></el-pagination>
        <div class="shoppingListbottom">
          <el-button type="primary" @click="purchase" v-if="!this.$route.query.orderNum">继续购卡</el-button>
          <!-- <el-button type="primary" @click="settleAccount">去结算</el-button> -->
        </div>
      </div>
      <div  v-else  class="empty-hint">
        <div class="empty-hint__wrapper">
          <div class="empty-hint__content">购物车空空的哦~</div>
           <el-button type="primary" @click="purchase">马上去购卡</el-button>
        
        </div>
      </div>
    </div>
    </div>
   
  </div>
</template>

<script>
import onresize from "@/mixins/resize.js";
import loading from "@/utils/Loading.js";
import {getShoppingList,deleteCarInfo,updateCarInfo,findCarInfo,getInfoDetail,adminListShop} from "@/api/onlinePurchase.js";
import dictGroup from "@/mixins/dictGroup.js";
import {
 enterpriseList
} from "@/api/partner.js";
import store from "@/store";
export default {
  name: "",
  mixins: [onresize,dictGroup],
  data() {
    return {
      shoppingList: [
       
      ],
      page:1,
      pageSize:15,
      total:0,
      shoppingCart:{
      userId:"",
      },
        customerList:[],
    };
  },
  created() {
      
    console.log(this.$route.query.orderNum)
    if(this.$route.query.orderNum){
      this.getInfoDetail()
    }else{
      this.getShoppingList()
    }
    this.getEnterpriseList()
  },
  watch: {},
  computed: {
    getTotalPrice() {
      let price = 0;
      this.shoppingList.forEach((item) => {
        // console.log(parseFloat(item.totalPrice * item.num));
        price = price + parseFloat(item.totalFee);
      });
      return price.toFixed(2);
    },
  },
  methods: {
     onSearch(){
      this.page = 1
            this.getShoppingList()
        },
        onPageChange(page){
			this.page = page
			this.getShoppingList()
		},
		handleSizeChange(size){
			this.pageSize = size
			this.getShoppingList()
        },
       getEnterpriseList() {
      let params = {
        pageSize: 100000,
        pageNo: 1,
      };
     
      enterpriseList(params).then((res) => {
        this.customerList = res.rows;
      });
    },
     toRenZhen(){
				this.$router.push({path:'/enterprise/AccountSettings',query:{id:"quality"}})
			},
    getInfoDetail(){
      getInfoDetail({orderNum:this.$route.query.orderNum}).then(res=>{
        // console.log(res)
        this.shoppingList= res
      })
    },
     
    getShoppingList(){
       let params={
                pageSize:this.pageSize,
                pageNo:this.page,
                userId:this.shoppingCart.userId,
            }
     adminListShop(params).then(res=>{
       console.log(res)
       this.shoppingList= res.rows
       this.total =  res.total
        // console.log(this.shoppingList)
     })
    },
    purchase() {
      //继续购卡跳转到商店界面
this.$router.push({ path: "/purchaseCard/buyOfficialCard" });
      
    },
    settleAccount() {
      //结算跳转到提交订单界面
      for(let item of this.shoppingList ){
            if(item.expire){
           this.$alert('请先删除失效商品', '提示', {
						    confirmButtonText:'确定',
						});
						return
        }
      }
     
      let idarr =[]
      this.shoppingList.forEach(item=>{
        idarr.push(item.id)
      })

      console.log(  JSON.stringify(idarr) )
      this.$router.push({ path: "/shopping/submitOrder",query:{id:JSON.stringify(idarr),detail:this.shoppingList} });
    },

    handleChange(id,val) {
      console.log(val,id);
      updateCarInfo({id:id,cardCount:val}).then(res=>{
        findCarInfo({id}).then(res=>{
          this.shoppingList.filter(item=>{
            if(item.id==id){
               item.totalFee = res.totalFee
            }
          })
          // console.log(this.shoppingList)
        })
        // this.getShoppingList() 
      })
    },
    delShoppingItem(index,id) {
     
      deleteCarInfo({id}).then(res=>{
        //  this.shoppingList.splice(index, 1);
        this.$message({
              type: "success",
              message: "删除成功",
            });
            this.getShoppingList()

      }).catch(error=>{
         this.$message({
              type: "success",
              message: "删除失败",
            });
      })
    },
    totalInfo(item) {
      let str = "";
      let  cardFuncStr= item.cardFuncStr=="null"?"无":item.cardFuncStr
      let autoRenew=item.autoRenew == 1?"是":"否"
      str +=
        "卡片功能: " +
       cardFuncStr  +
        "<br>" +
        "套餐分类: " +
        item.mealName 
        // "<br>" +
        // "已打开自动续费功能：" + autoRenew  ;
      return str;
    },
  },
};
</script>

<style>
.shoppingCart .el-form-item__label {
  vertical-align: top;
}
.shoppingCart .shoppingListHead {
  display: flex;
  color: #666;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #f5f5f5;
}
.shoppingCart .flex-6 {
  flex: 8 1 0%;
}
.shoppingCart .flex-2 {
  flex: 2;
}
.shoppingCart .flex-3 {
  flex: 3;
}
.shoppingCart .flex-1 {
  flex: 1;
}
.shoppingCart .shoppingListHead > span:not(:first-child) {
  text-align: center;
}
.shoppingListBody .list_body_item {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
}
.list_body_item .item-1-1 {
  display: inline-block;
}
.list_body_item .item-1-1 img {
  width: 80px;
  height: 80px;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
}
.list_body_item .item-1-2 {
  display: inline-block;
  vertical-align: top;
}
.list_body_item .width-70 {
  width: 70% !important;
}
.shoppingCart .shoppingListBody .list_body_item .item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.shoppingCart .list_body_item .goods-attribute {
  line-height: 1.4;
  margin-left: 10px;
}
.shoppingCart .list_body_item .margin-bottom10 {
  margin-bottom: 10px !important;
}
.shoppingCart .goods-attribute .left-width {
  width: 150px;
}
.shoppingCart .f-size-l9 {
  font-size: 12px !important;
}
.goods-attribute .right-width {
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.shoppingCart .shoppingListFooter {
  border-bottom: 1px solid #f5f5f5;
  padding: 40px 20px 40px 40px;
  text-align: right;
}
.shoppingListFooter .f-bold {
  font-weight: 600;
}
.shoppingListFooter .f-size-l3 {
  font-size: 20px !important;
}
.shoppingCart .shoppingListbottom {
  padding: 20px;
  text-align: right;
}
.shoppingCart .empty-hint .empty-hint__wrapper {
    text-align: center;
    margin-top: 40px;
}
.shoppingCart .empty-hint .empty-hint__wrapper .empty-hint__content{
  margin-bottom:40px;
}
.shoppingCart  .isexpire{background: #ccc;}
.noRenZhen{line-height: 60px; color:#999; text-align: center;}
</style>
