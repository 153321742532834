export default{
	data(){
		return {
			w_TOTYPE_ : [],   //业务类型
            w_PRODUCT_ : [],  //产品类型
            w_COTYPE_ : [],   //合作类型
            w_ACCOUNT_ : [],  //运营商
            w_operator_group: [], //webui定义的 运营商
            w_sellType: [], //webui定义的 套餐类型
            w_MEALTYPE_: [],  //套餐类型
            w_algorithm_group:[], //web定义的限速算法

            w_packageType:[], //套餐的type
            w_secondCaterogy_yd :[],  //xiaoli定义  secondCaterogy_yd
            w_defaultStrategy:[], //xiaoli 虚量
            w_interfaceName:[],
            w_agent_business_type:[],
            w_audience_object:[],
            w_interface:[], //接口列表
            w_platform_protocol:[],
            w_coupon_type:[]
		}
    },
    mounted () {
        this.w_TOTYPE_ = this.$store.getters.TOTYPE_
        this.w_PRODUCT_ = this.$store.getters.PRODUCT_
        this.w_COTYPE_ = this.$store.getters.COTYPE_
        this.w_ACCOUNT_ = this.$store.getters.ACCOUNT_
        this.w_operator_group = this.$store.getters.operator_group
        this.w_sellType = this.$store.getters.sellType
        this.w_MEALTYPE_ = this.$store.getters.MEALTYPE_
        this.w_algorithm_group = this.$store.getters.algorithm_group
        this.w_packageType = this.$store.getters.packageType
        this.w_secondCaterogy_yd = this.$store.getters.secondCaterogy_yd
        this.w_defaultStrategy = this.$store.getters.defaultStrategyList
        this.w_interfaceName = this.$store.getters.interfaceName
        this.w_agent_business_type = this.$store.getters.agent_business_type
        this.w_audience_object = this.$store.getters.audience_object
        this.w_interface= this.$store.getters.interfaceList
        this.w_platform_protocol = this.$store.getters.platform_protocol
        this.w_coupon_type = this.$store.getters.coupon_type
    },
    methods:{
        _getCoType(value){
            return this._getGroupName(value,this.w_COTYPE_)
        },
        _getProductType(value){
            return this._getGroupName(value,this.w_PRODUCT_)
        },
        _getToType(value){
            return this._getGroupName(value,this.w_TOTYPE_)
        },
        _getMealType(value){
            return this._getGroupName(value,this.w_MEALTYPE_)
        },
        _getCategory(value,type){
            return this._getGroupName(value,this.w_ACCOUNT_,"",type)
        },
        _getStrategy(value){
            if(!!value){
                return this._getGroupName(value,this.w_defaultStrategy)
            }
            return '-'
        },
        _getInterfaceName(value){
            return this._getGroupName(value,this.w_interfaceName)
        },
        _getAudienceObjct(value){
            return this._getGroupName(value,this.w_audience_object)
        },
        _getCoupon_type(value){
            return this._getGroupName(value,this.w_coupon_type)
        },
        
        _getGroupName(value,dictGroup,defaultName,type){
            let groupName = defaultName ? defaultName : '全部'
               if(!!type){
                   if(value=="11"){
                    groupName = "虚拟卡运营商-ZJ-JN"
                   }
               }
            for(let i=0; i<dictGroup.length; i++){
                if(value == dictGroup[i].dictValue){
                    groupName = dictGroup[i].groupName
                    break
                }
            }
            return groupName
        },
        _getCoTypeValue(key){
            return this._getDictValue(key,this.w_COTYPE_)
        },
        _getProductValue(key){
            return this._getDictValue(key,this.w_PRODUCT_)
        },
        _getToTypeValue(key){
            return this._getDictValue(key,this.w_TOTYPE_)
        },
        _getStrategyValue(key){
            return this._getDictValue(key,this.w_defaultStrategy)
        },
        _getDictValue(key,dictGroup){
            let dictValue = ''
            if(dictGroup){
                for(let i=0; i<dictGroup.length; i++){
                    console.log(key,dictGroup[i].dictKey)
                    if(key == dictGroup[i].dictKey){
                        dictValue = dictGroup[i].dictValue
                        break
                    }
                }
            }
           
            return dictValue
        }
    }
}