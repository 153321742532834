/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './request.js'
export function googList(params) {//商店产品列表
    return request({
        url: '/buy/product/list4Agent.do?',
        method: 'post',
        data: params
    })
}
export function getShoppingList(params) {//购物车列表
    return request({
        url: '/buy/car/list.do?',
        method: 'post',
        data: params
    })
}
export function availableList(params) {//可用商品列表
    return request({
        url: '/buy/limit/findById.do?',
        method: 'post',
        data: params
    })
}
export function allChoiceList(params) {//字典接口
    return request({
        url: "/buy/fee/allChoice.do?",
        method: 'post',
        data: params
    })
}
export function meterialMixList(params) {//材料规格组合列表
    return request({
        url: "/buy/fee/list.do?",
        method: 'post',
        data: params
    })
}
export function addCar(params) {//加入购物车
    return request({
        url: "/buy/car/add.do?",
        method: 'post',
        data: params
    })
}

export function deleteCarInfo(params) {//删除购物车商品
    return request({
        url: "/buy/car/deleteById.do?",
        method: 'post',
        data: params
    })
}
export function updateCarInfo(params) {//修改购物车商品
    return request({
        url: "/buy/car/update.do?",
        method: 'post',
        data: params
    })
}
export function findCarInfo(params) {//查询购物车商品
    return request({
        url: "/buy/car/findById.do?",
        method: 'post',
        data: params
    })
}

export function addressList(params) {//收货地址列表
    return request({
        url: "/buy/address/list.do?",
        method: 'post',
        data: params
    })
}

export function addAddress(params) {//添加收货地址
    return request({
        url: "/buy/address/add.do?",
        method: 'post',
        data: params
    })
}


export function updateAddress(params) {//编辑收货地址
    return request({
        url: "/buy/address/update.do?",
        method: 'post',
        data: params
    })
}

export function expressList(params) {//快递列表
    return request({
        url: "/buy/express/list.do?",
        method: 'post',
        data: params
    })
}
export function addOrder(params) {//购物车结算
    return request({
        url: "/buy/order/carBuy.do?",
        method: 'post',
        data: params
    })
}
export function directBuy(params) {//立即结算
    return request({
        url: "/buy/order/directBuy.do?",
        method: 'post',
        data: params
    })
}

export function payOrder(params) {//订单支付
    return request({
        url: "/buy/order/pay.do?",
        method: 'post',
        data: params
    })
}
export function getLeftMoney(params) {//获取余额
    return request({
        url: "/agent/getLoginUser.do?",
        method: 'post',
        data: params
    })
}



export function orderList(params) {//订单列表
    return request({
        url: "/buy/order/list.do?",
        method: 'post',
        data: params
    })
}

export function exportOrderCardList(params) {//导出卡片订单列表
    return request({
        url: "/buy/order/exportOrderCard.do?",
        method: 'post',
        data: params
    })
}
export function cancelOrder(params) {//导出卡片订单列表
    return request({
        url: "/buy/order/cancel.do?",
        method: 'post',
        data: params
    })
}
export function getInfoDetail(params) {//再次购买的购物车信息
    return request({
        url: "/buy/order/againBuyQuery.do?",
        method: 'post',
        data: params
    })
}

export function getDetaileList(params) {//
    return request({
        url: "/buy/order/findByOrderNum.do?",
        method: 'post',
        data: params
    })
}

export function getOrderpageList(params) {//
    return request({
        url: "/buy/order/pageList.do?",
        method: 'post',
        data: params
    })
}
export function confirmRev(params) {// 确认收货
    return request({
        url: "/buy/order/confirmRev.do?",
        method: 'post',
        data: params,
    })
}

export function getQAList(params){//获取qa列表查询
    return request({
        url: '/QA/getPageList.do?',
        method: 'post',
        data: params
    })
}

export function addByAgent(params){//加入购物车
    return request({
        url: '/buy/car/addByAgent.do?',
        method: 'post',
        data: params
    })
}
export function adminListShop(params){//购物车列表
    return request({
        url: '/buy/car/adminList.do?',
        method: 'post',
        data: params
    })
}
// 

























